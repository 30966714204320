html {
  height: 100%;
}

body {
  line-height: 1.4;
  font-family: 'Roboto', sans-serif;
  color: #67696c;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px;
  overflow-x: hidden;
  height: 100%;
  color: #67696c;
}

ol,
ul {
  list-style: none;
}

p {
  font-size: 16px;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 80px;
}

.border-bottom-dotted {
  border-bottom: dotted;
  border-bottom-width: thin;
  margin-bottom: 15px !important;
}

.section-padding {
  padding: 40px 0px;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.page-top-image img {
  width: 100%;
  height: auto;
  max-height: 320px;
}

.logo {
  width: 130px;
  height: 40px;
  background: url(https://www.bharti.com/images/bharti_logo.png);
  text-indent: -9000px;
}

.bnr-title {
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 800;
  color: #67696c;
  margin-bottom: 12px;
}

.bnr-sub-title {
  font-size: 18px;
  font-weight: 600;
  color: #67696c;
}

@media (min-width: 20px) and (max-width: 767px) {
  .banner-info {
    padding-top: 40px;
  }

  .bnr-title {
    font-size: 24px;
  }

  p {
    font-size: 14px;
  }
}

/************************************* Header CSS *************************************/

.navbar-nav .nav-link {
  font-weight: 600;
}

.navbar-nav .nav-link.active {
  font-weight: 600;
  color: #2f97da !important;
  border-bottom: 2px solid#fab41d;
}

.navbar {
  height: 80px;
}

@media (min-width: 20px) and (max-width: 767px) {
  .navbar {
    height: auto;
  }
}

/************************************* Management Page CSS *************************************/

.managmentteam .image img {
  max-width: 100%;
  width: 85%;
}

.managmentteam .card-title {
  font-size: 28px;
  font-weight: 700;
}

.managmentteam .card-title small {
  font-size: 18px;
  color: #2f97da;
}

.managmentteam .card-text p {
  font-size: 16px;
}

@media (min-width: 20px) and (max-width: 767px) {
  .managmentteam .image img {
    max-width: 200px;
    margin-bottom: 24px;
  }

  .managmentteam .card-text p {
    font-size: 14px;
  }
}

/************************************* Footer CSS *************************************/

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px 0px 10px;
  background: #f8f8f8;
}